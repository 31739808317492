import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import StyledHero from '../components/StyledHero';
import Banner from '../components/Banner';
import Card from '../components/Card';
import styles from '../css/index.module.css';
import TextBlock from '../components/TextBlock';

const IndexTemplate = ({ data }) => {
  const {
    page_title,
    header,
    cards,
    content
  } = data.markdownRemark.frontmatter;

  const renderCards = () => {
    if (cards) {
      return cards.map(({ card }, idx) => {
        return (
          <Card
            key={idx}
            img={card.image.childImageSharp.fluid}
            title={card.cta.text}
            url={card.cta.url}
          />
        );
      });
    }
  };

  const renderContent = () => {
    if (content) {
      return content.map((contentBlock, idx) => {
        return (
          <article key={idx} className={styles.textContent}>
            <TextBlock content={contentBlock} />
          </article>
        );
      });
    }
  };

  return (
    <Layout>
      <SEO title={page_title} />
      <StyledHero
        img={header.image.childImageSharp.fluid}
        home="true"
        center="true"
      >
        <Banner title={header.banner_main} info={header.banner_sub}>
          <Link to={header.cta_1.url} className="btn-white">
            {header.cta_1.text}
          </Link>
        </Banner>
      </StyledHero>
      <section className={styles.cards}>
        <div className={styles.center}>{renderCards()}</div>
      </section>
      <section className={styles.textContainer}>{renderContent()}</section>
    </Layout>
  );
};

export default IndexTemplate;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        page_title
        header {
          image {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          banner_main
          banner_sub
          cta_1 {
            text
            url
          }
          cta_2 {
            text
            url
          }
        }
        cards {
          card {
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            cta {
              text
              url
            }
          }
        }
        content {
          title
          subtitle
          text
          cta {
            text
            url
          }
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

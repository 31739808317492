import React from 'react';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import styles from '../css/card.module.css';

const Card = ({ img, title, url }) => {
  const link = url.replace(/\s/g, '-').toLowerCase();

  return (
    <section className={styles.card}>
      <div className={styles.imgContainer}>
        <Image fluid={img} title={title} className={styles.img} />
        <Link to={link} className={styles.link}>
          {title}
        </Link>
      </div>
      <div className={styles.linkContainer}>
        <Link to={link} className={styles.linkMobile}>
          {title}
        </Link>
      </div>
    </section>
  );
};

export default Card;
